var axios = require('axios');
let NODE_UAT_URL = " https://uat.msilrail.in/foxtrot"
var axiosInstance = axios.create({

  // baseURL: 'http://localhost:5000/foxtrot',
  baseURL: NODE_UAT_URL,
  /* other custom settings */
});

module.exports = axiosInstance;
