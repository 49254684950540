import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import LoginForm from './Loginform';

import {loginClient} from "../../common-partner-login-sdk/lib/esm"

//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

var redirectURL = require('../redirectURL');

class Logout extends Component {

    constructor(props) {
        super(props);
        console.log("logout...........")
        try{
            // loginClient.federatedLogout({client_id:"7btrataiqj1mef1sjhemks7h85",logout_uri:"https://msilvendortrucknav.com/logout"}).then((res) => {
            loginClient.federatedLogout("2f35qtedlb8tpeok86t3p6j0ch","https://uat.msilrail.in/logout/").then((res) => {
                console.log(res,'1686-------------------');
               
              }).catch(e=> console.log("error in adfs"));
        }catch(e){
            console.log("error in adfs login")
        }
         var loginType = localStorage.getItem("is_enmovil_transporter");
                localStorage.removeItem('token');
                localStorage.removeItem('userid');
                localStorage.removeItem('dept_code');
                localStorage.removeItem('firstname');
                localStorage.removeItem('lastname');
                localStorage.removeItem('email');
                localStorage.clear();
                delete axios.defaults.headers.common['Authorization'];
                this.setState({
                    redirectToReferrer:''
                });
                    // this.setState({
                    //     redirectToReferrer:''
                    // });
                    //console.log("logout successfully",localStorage.getItem('token'));
                  
                  
                    window.location.href="/";
                   //  return res.status(200).send({ adfs_data: res.data });
        // var userloggparams = {
        //     token: localStorage.getItem('token'),
        //     userId:localStorage.getItem('userid'),
        //     logoutype:"manual"

        // }
        // redirectURL.post("/manage/removeloggeduser",userloggparams)
        // .then((resp) => {
        //     console.log("resp ", resp)

           
            
        // })
    }
    componentDidMount(){
        console.log("logout hitting")
    }
}


export default Logout;
